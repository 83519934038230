<template>
  <div>
    <loading v-if="displayLoading"></loading>
    <div class="page">
      <div class="page__title">
        {{ getModuleConfig("active.title") }}
      </div>
      <div class="page__desc">
        <div class="text-center mt-3 mb-5">
          <h5>{{ getModuleConfig("active.sub_title") }}</h5>
        </div>
      </div>
      <form class="form">
        <!-- fields loop start -->
        <div v-for="(field, index) in verifyFields" :key="index">
          <!-- text type -->
          <div v-if="field.type === 'text' && field.matched_key"  class="field">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
              >{{ field.title }}</label
            >
            <input
              class="field__input"
              v-model="form[field.matched_key]"
              :class="{ invalid: v$.form[field.matched_key].$error }"
              :placeholder="displayPlaceholder(field)"
              :required="field.required"
            />
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div
              v-if="v$.form[field.matched_key].$error"
              class="form__error-msg"
            >
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
          <!-- text type -->
        </div>
        <!-- fields loop end -->
      </form>
    </div>
    <div class="page__button">
      <SharedButton
        class="s-btn-bg-primary"
        type="button"
        @click="handleSubmit"
      >
        送出
      </SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import LiffChecker from "@/utils/LiffChecker";
import _ from "lodash";

const MODULE_CODE = "staff_active";

export default {
  components: {
    Loading,
    SharedButton,
  },

  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      displayLoading: false,
      verifyFields: [],
      lineData: {},
      form: {},
    };
  },

  validations() {
    let form = {};

    for (let i = 0; i < this.verifyFields.length; i++) {
      let field = this.verifyFields[i];
      // 特殊規則 手機
      if (field.matched_key === "mobile_phone") {
        form.mobile_phone = this.isFieldRequired("mobile_phone")
          ? {
              required,
              mobile: (val) => {
                return /09[0-9]{8}/.test(val);
              },
            }
          : {};

        continue;
      }

      // 一般欄位規則
      form[field.matched_key] = field.required === true ? { required } : {};
    }
    return {
      form,
    };
  },

  mounted() {
    this.init()
  },

  methods: {
    async init() {
      this.verifyFields = _.sortBy(this.getModuleConfig('active.verify_field'), 'order')

      const checker = new LiffChecker(this.$route.params.orgCode);
      this.lineData = await checker.getLineData();
    },
    isFieldRequired(key) {
      return this.verifyFields.some(
        (field) => field.matched_key === key && field.required == true
      );
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      this.displayLoading = true;
      try {
        if (this.getModuleConfig('verify_mobile.enable')) {
          this.$router.push({
            name: "LiffStaffValidate",
            query: this.formatPayload()
          });
          return;
        }
        const url =
          process.env.VUE_APP_API_BASE_URL +
          "/" +
          this.$route.params.orgCode +
          "/liff/staff_active";

        await axios
          .post(url, this.formatPayload())
          .then((response) => {
            if (response.data.status === "success") {
              this.$router.push({
                name: "LiffStaffActiveSuccess",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (
              error.response.data.status === "failed" &&
              error.response.data.message
            ) {
              this.$swal('啟用失敗', error.response.data.message, 'danger');
            }
          });
      } catch (error) {
        console.log("error", error);
      }
      this.displayLoading = false;
    },

    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    formatPayload() {
      let payload = this.form
      payload.line_id = this.lineData.profile.userId
      payload.line_access_token = this.lineData.accessToken
      return payload
    },
    displayPlaceholder(field, defaultMessage = null) {
      if (field.placeholder) return field.placeholder;
      if (defaultMessage) return defaultMessage;
      if (field.title) return "請輸入" + field.title;
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.form__error-msg {
  color: #fe0000;
  font-size: 12px;
  margin-top: 8px;
}
.code-input {
  margin-bottom: 32px;
  ::v-deep .react-code-input-container {
    width: 100% !important;
    text-align: center;
    &.invalid input {
      border: 1px solid #fe0000;
    }
  }
  ::v-deep input {
    border: 1px solid #636366;
    border-radius: 10px !important;
    font-size: 50px;
    font-weight: 600;
    width: 60px;
    font-family: PingFang TC;
    color: var(--liff-primary_text_color);

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    &:focus {
      border: 1px solid var(--liff-primary_text_color);
      caret-color: var(--liff-primary_text_color);
    }
    &:focus + input {
      border: 1px solid #636366;
    }
  }
}
.page__bottom {
  font-size: 14px;
  line-height: 22px;
  color: var(--liff-secondary_text_color);
  text-align: center;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
